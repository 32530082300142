import { useContext } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { AuthContext2 } from "../providers/AuthProvider2";
import CmoLogin from "./CmoLogin";
import QubasUserLogin from "./QubasUserLogin";

export default function CmoProtectedRoutes() {
    const authContext = useContext(AuthContext2);
    if(authContext.isAuthenticated && authContext.currentUserType() == 1)
    {
        return( <div id="cmo-user"><Outlet /></div>);
    }

    return (<CmoLogin />);

  }