import { ReactNode, useEffect } from "react";
import { AccessKeyDetails } from "./AccessKeyManagement";

export type ColumnDefinitionType<T, K extends keyof T> = {
    key: K;
    header: string;
    width?: number;
}

export type TableProps<T, K extends keyof T> = {
    // giving table name is mandatory as it is being used in the key/index name
    // of table header and rows.
    name: string
    data: Array<T>;
    columns: Array<ColumnDefinitionType<T, K>>;
    isEditable: boolean;
    onEdit: (params: T) => void;
    hasAccessKey?: boolean;
    renewAccessKey?: (params: string) => void;
    hasActivation?: boolean;
    activated?: (params: number) => void;
}

type TableHeaderProps<T, K extends keyof T> = {
    tableName: string;
    columns: Array<ColumnDefinitionType<T, K>>;
    isEditable: boolean;
    hasAccessKey?: boolean;
    hasActivation?: boolean;
}

export const TableHeader = <T, K extends keyof T>({ tableName, columns, isEditable, hasAccessKey, hasActivation }: TableHeaderProps<T, K>): JSX.Element => {
    const headers = columns.map((column, index) => {
        const style = {
            minWidth: column.width ?? "100px", // 100 is our default value if width is not defined
            // borderBottom: '2px solid black'
            backgroundColor: '#5B9BD5',
            padding: '10px',
        };

        return (
            <th
                key={`${tableName}-headCell-${index}`}
                style={style}
            >
                {column.header}
            </th>
        );
    });

    const headerStyle = {
        width: 10, // 100 is our default value if width is not defined
        // borderBottom: '2px solid black'
        backgroundColor: '#5B9BD5',
        padding: '10px',
    };

    return (
        <thead>
            <tr>
                {headers}
                {isEditable && <th key={'editcolumn'} style={headerStyle}>Edit</th>}
                {hasAccessKey && <th key={'renewAccessKeyColumn'} style={headerStyle}>Renew Access Key</th>}
                {hasActivation && <th key={'activeColumn'} style={headerStyle}>Active</th>}
            </tr>
        </thead>
    );
};

type TableRowsProps<T, K extends keyof T> = {
    tableName: string
    data: Array<T>;
    columns: Array<ColumnDefinitionType<T, K>>;
    isEditable: boolean,
    onEditClick: (params: T) => void,
    hasAccessKey?: boolean;
    renewAccessKey?: (params: string) => void;
    hasActivation?: boolean;
    activated?: (params: number) => void;

}


const style = {
    // border: '1px solid black'
    padding: '10px'
}

export const TableRows = <T, K extends keyof T>({ tableName, data, columns, isEditable, onEditClick, hasAccessKey, renewAccessKey, hasActivation, activated }: TableRowsProps<T, K>): JSX.Element => {
    const rows = data.map((row, index) => {
        return (
            <tr key={`row-${index}`}>
                {columns.map((column, index2) => {
                    return (
                        <td title={column.key === "cmoName" ? (row as AccessKeyDetails)["pocName"] : ""} key={`${tableName}-cell-${index}-${index2}`} style={style}>
                            {/* TODO: Check whether converting to ReactNode is fine */}
                            {row[column.key] as ReactNode}
                        </td>
                    );
                }
                )}
                {
                    isEditable && <td key={`${tableName}-editcell-${index}`} style={style}>
                        <span className="table-edit-btn" onClick={() => { onEditClick(row) }}></span>
                    </td>
                }
                {
                    hasAccessKey && <td key={`${tableName}-renew-ak-cell-${index}`} style={style}>
                        <span className="table-renew-ak-btn" onClick={() => {
                            // checking if renewaccesskey object exists and only then calling it
                            // this is because renewaccesskey object is an optional parameter
                            renewAccessKey && renewAccessKey((row as AccessKeyDetails)["accessKey"] as string)
                        }}>
                        </span>
                    </td>
                }
                {
                    hasActivation && <td key={`${tableName}-activate-channel-cell-${index}`} style={style}>
                        <input type="checkbox" className="table-activate-channel-chk-box"
                            checked={(row as AccessKeyDetails)["accessKeyIsActive"] as boolean}
                            onChange={() => {
                                // checking if renewaccesskey object exists and only then calling it
                                // this is because renewaccesskey object is an optional parameter
                                activated && activated((row as AccessKeyDetails)["accessKeyId"] as number)
                            }} />
                    </td>
                }
            </tr>
        );
    });

    return (
        <tbody>
            {rows}
        </tbody>
    );
};

interface TableFooter<T, K extends keyof T> {
    range: number[],
    setPage: (page: number) => void,
    page: number,
    slice: Array<T>
}

export const TableFooter = <T, K extends keyof T>({ range, setPage, page, slice }: TableFooter<T, K>): JSX.Element => {

    // useEffect(() => {
    //     if (slice.length < 1 && page !== 1) {
    //         setPage(page - 1);
    //     }
    // }, [slice, page, setPage]);
    return (
        <div className="table-footer">
            {range.map((el, index) => (
                <button
                    key={index}
                    className={`${"pagination-button"} ${page === el ? "active-button" : "inactive-button"
                        }`}
                    onClick={() => {setPage(el)}} >
                    {el}
                </button>
            ))}
        </div>
    );
};

