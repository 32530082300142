import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext2 } from "../../providers/AuthProvider2";
import { TableHeader, TableRows, TableProps, ColumnDefinitionType, TableFooter } from "./CmoDashboardTable";
import configData from "./../../app-config.json";
import usePagination from "../../hooks/usePagination";


interface FileUploadHistoryModel {
    id: number,
    fileName: string,
    mD5: string,
    fileType: string,
    importDate: string,
    status: string,
}

const style2 = {
    // borderCollapse: 'collapse'
} as const

const FileUploadHistoryTable = <T, K extends keyof T>({ name, data, columns, downloadCsv }: TableProps<T, K>): JSX.Element => {
    const [page, setPage] = useState(1);
    const { slice, range } = usePagination(data, page, 10);
    function setP(param: number) {
        setPage(param);
    }
    return (
        <div className="file-upload-history-table">
            <table style={style2}>
                <TableHeader tableName={name} columns={columns} />
                {
                    data == null &&
                    <tbody>
                        <tr className="no-record-found">
                            <td colSpan={columns.length}>No Record Found</td>
                        </tr>
                    </tbody>

                }
                <TableRows
                    tableName={name}
                    data={slice}
                    columns={columns}
                    onDownloadCsv={downloadCsv}
                />
            </table>
            {range.length > 0 && <TableFooter range={range} slice={slice} setPage={setP} page={page} />}
        </div>

    );
};


async function handleDownloadFiles(token: string, fileId: number, fileName: string, fileType: string, removeTokenFromSession: () => void) {
    let downloadParams = {
        fileType: fileType,
        fileId: fileId,
    }

    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const body = JSON.stringify(downloadParams);
    const apiUrl = configData.SERVER_URL;
    const url = "filedownloads";
    await fetch(apiUrl + url,
        {
            method: "Post",
            body,
            headers,
        }).then(res => {
            if (res.ok) {
                return res.blob();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then((blob) => {
            //detect whether the browser is IE/Edge or another browser
            // if (window.navigator && window.navigator) {
            //   //To IE or Edge browser, using msSaveorOpenBlob method to download file.
            //   window.navigator.msSaveOrOpenBlob(blob, fileName);
            // }
            if (blob) {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
}

async function handleGetFileUploadHistory(token: string, removeTokenFromSession: () => void) {
    let tData: [] = [];
    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const apiUrl = configData.SERVER_URL;
    const url = "cmofileupload";


    await fetch(apiUrl + url,
        {
            method: "Get",
            headers,
        }).then(res => {

            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    tData = data.responseBody;
                    // toast.info(data.responseMessage);
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server')
        });
    return tData;
}

export default function FileUploadHistory({ hideLoader, showLoader, forceUpdateTrigger }: { hideLoader: () => void, showLoader: () => void, forceUpdateTrigger: number }): JSX.Element {

    const authContext = useContext(AuthContext2);
    const [tblData, setTableData] = useState<FileUploadHistoryModel[]>([]);


    const fetchFileUploadHistory = async () => {
        showLoader();
        const result = await handleGetFileUploadHistory(authContext.getTokenFromSession(), authContext.removeTokenFromSession);
        setTableData(result);
        hideLoader();
    };

    useEffect(() => {
            fetchFileUploadHistory();
    }, [forceUpdateTrigger])

    async function handleDownloadCsv(fileDetails: FileUploadHistoryModel) {
        await handleDownloadFiles(authContext.getTokenFromSession(), fileDetails.id, fileDetails.fileName, "uploadedcsv", authContext.removeTokenFromSession);
    }
    // const handleClose = (params:CMODetails) => setCmoFormData(params);

    // function handleEdit(accessKeyDetails: AccessKeyDetails) {
    //     setAccessKeyFormData(accessKeyDetails)
    // }

    const columnDef: ColumnDefinitionType<FileUploadHistoryModel, keyof FileUploadHistoryModel>[] = [
        {
            key: 'fileName',
            header: 'File Name',
            width: 150,
            isCsvDownload: true
        },
        {
            key: 'mD5',
            header: 'MD5',
        },
        {
            key: 'importDate',
            header: 'Import Date'
        },
        {
            key: 'status',
            header: 'Status'
        }
    ]

    return (
        <div className="">

            <div className="">
                <FileUploadHistoryTable
                    name="file-upload-history"
                    data={tblData}
                    columns={columnDef}
                    downloadCsv={handleDownloadCsv}
                />
            </div>
        </div>
    );

}