import { useState, useEffect } from "react";

function calculateRange<T>(data: T[], rowsPerPage: number) {
    const range = [];
    const num = Math.ceil(data.length / rowsPerPage);
    let i = 1;
    for (let i = 1; i <= num; i++) {
        range.push(i);
    }

    return range;
};

function sliceData<T>(data: T[], page: number, rowsPerPage: number) {
    return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

export default function usePagination<T>(data: T[], page: number, rowsPerPage: number) {
    const [tableRange, setTableRange] = useState<number[]>([]);
    const [slice, setSlice] = useState<any[]>([]);

    useEffect(() => {
        if (data && data.length > 0) {
            const range = calculateRange(data, rowsPerPage);
            setTableRange([...range]);

            const slice = sliceData(data, page, rowsPerPage);
            setSlice([...slice]);
        }
    }, [data, setTableRange, page, setSlice]);

    return { slice, range: tableRange };
};
