import { ReactNode, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import usePagination from "../../hooks/usePagination";
import { AuthContext2 } from "../../providers/AuthProvider2";
import CmoMethodPairForm from "./CmoMethodPairForm";
import { ColumnDefinitionType, TableFooter, TableHeader, TableProps, TableRows } from "./QubasUserDashboardTable";
import configData from "./../../app-config.json";

export interface AccessKeyDetails {
    accessKeyId?: number,
    cmoId: number,
    methodId: number,
    accessKey?: string
    pocName?: string
    accessKeyIsActive?: boolean
}

interface AccessKeyDetailsTable extends AccessKeyDetails {
    methodName: string,
    autoImportPath: string,
    cmoName: string,
}

const accessKeyTable = {
    // borderCollapse: 'collapse'
} as const

const AccessKeyTable = <T, K extends keyof T>({ name, data, columns, isEditable, onEdit, hasAccessKey, renewAccessKey, hasActivation, activated }: TableProps<T, K>): JSX.Element => {
    const [page, setPage] = useState(1);
    const { slice, range } = usePagination(data, page, 4);
    function setP(para: number){
        setPage(para);
    }
    return (
        <>
            <table className="access-key-table">
                <TableHeader
                    tableName={name}
                    columns={columns}
                    isEditable={isEditable}
                    hasAccessKey={hasAccessKey}
                    hasActivation={hasActivation}
                />
                <TableRows
                    tableName={name}
                    data={slice}
                    columns={columns}
                    isEditable={isEditable}
                    onEditClick={onEdit}
                    hasAccessKey={hasAccessKey}
                    renewAccessKey={renewAccessKey}
                    hasActivation={hasActivation}
                    activated={activated}
                />
            </table>
            {range.length > 0 && <TableFooter range={range} slice={slice} setPage={setP} page={page} />}
        </>

    );
};


export interface MethodList {
    methodId: number,
    methodName: string,
}

export interface CMOList {
    cmoId: number,
    cmoName: string,
}

async function handleCmoMethodPairStatusUpdate(token: string, accessKeyId: number, removeTokenFromSession: () => void) {
    let keyUpdate: boolean = false;
    // let accesskey = {
    //     accessKey
    // }
    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };

    const body = JSON.stringify(accessKeyId);
    const apiUrl = configData.SERVER_URL;
    const url = "accesskeydetails/activate";

    await fetch(apiUrl + url,
        {
            method: "Put",
            headers,
            body
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    keyUpdate = true;
                    // toast.info(data.responseMessage);
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    return keyUpdate;
}


async function handleRenewAccessKey(token: string, accessKey: string, removeTokenFromSession: () => void) {
    let keyUpdate: boolean = false;
    // let accesskey = {
    //     accessKey
    // }
    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };

    const body = JSON.stringify(accessKey);
    const apiUrl = configData.SERVER_URL;
    const url = "accesskeydetails/renewkey";

    await fetch(apiUrl + url,
        {
            method: "Put",
            headers,
            body
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    keyUpdate = true;
                    // toast.info(data.responseMessage);
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    return keyUpdate;
}

async function handleGetCmoList(token: string, removeTokenFromSession: () => void) {
    let cmoList: [] = [];
    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const apiUrl = configData.SERVER_URL;
    const url = "lists/listofcmos";

    await fetch(apiUrl + url,
        {
            method: "Get",
            headers,
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    cmoList = data.responseBody;
                    // toast.info(data.responseMessage);
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    return cmoList;
}

async function handleGetMethodList(token: string, removeTokenFromSession: () => void) {
    let methodList: [] = [];
    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const apiUrl = configData.SERVER_URL;
    const url = "lists/notselectedmoethods";

    await fetch(apiUrl + url,
        {
            method: "Get",
            headers,
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    methodList = data.responseBody;
                    // toast.info(data.responseMessage);
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    return methodList;
}

async function handleGetAccessKeyDetails(token: string, removeTokenFromSession: () => void) {
    let tData: [] = [];
    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const apiUrl = configData.SERVER_URL;
    const url = "accesskeydetails";

    await fetch(apiUrl + url,
        {
            method: "Get",
            headers,
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    tData = data.responseBody;
                    // toast.info(data.responseMessage);
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }

        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    return tData;
}

export default function AccessKeyManagement({ hideLoader, showLoader }: { hideLoader: () => void, showLoader: () => void }): JSX.Element {
    const authContext = useContext(AuthContext2);
    const [tblData, setTableData] = useState<AccessKeyDetailsTable[]>([]);
    const [cmoList, setCmoList] = useState<CMOList[]>([]);
    const [methodList, setMethodList] = useState<MethodList[]>([]);

    const initialAccessKeyFormstate: AccessKeyDetails = {
        accessKeyId: 0,
        cmoId: 0,
        methodId: 0,
    }
    const [accessKeyFormData, setAccessKeyFormData] = useState<AccessKeyDetails>(initialAccessKeyFormstate);

    const fetchAccessKeyDetails = async () => {
        showLoader();
        const result = await handleGetAccessKeyDetails(authContext.getTokenFromSession(), authContext.removeTokenFromSession);
        setTableData(result);
        hideLoader();
    };
    const fetchCmoList = async () => {
        showLoader();
        const result = await handleGetCmoList(authContext.getTokenFromSession(), authContext.removeTokenFromSession);
        setCmoList(result);
        hideLoader();
    };
    const fetchMethodList = async () => {
        showLoader();
        const result = await handleGetMethodList(authContext.getTokenFromSession(), authContext.removeTokenFromSession);
        setMethodList(result);
        hideLoader();
    };

    useEffect(() => {
        fetchAccessKeyDetails();
        fetchCmoList();
        fetchMethodList();
    }, []);

    // const handleClose = (params:CMODetails) => setCmoFormData(params);

    function handleEdit(accessKeyDetails: AccessKeyDetails) {
        setAccessKeyFormData(accessKeyDetails)
    }

    const renewAccessKey = async (accessKey: string) => {
        const result = await handleRenewAccessKey(authContext.getTokenFromSession(), accessKey, authContext.removeTokenFromSession);
        if (result) {
            fetchAccessKeyDetails();
        }
    };

    const refreshLists = async () => {
        await fetchCmoList();
        await fetchMethodList();
        await fetchAccessKeyDetails();

    };

    const activateAccessKey = async (accessKeyId: number) => {
        const result = await handleCmoMethodPairStatusUpdate(authContext.getTokenFromSession(), accessKeyId, authContext.removeTokenFromSession);
        if (result) {
            fetchAccessKeyDetails();
            fetchCmoList();
            fetchMethodList();
        }
    };

    const columnDef: ColumnDefinitionType<AccessKeyDetailsTable, keyof AccessKeyDetailsTable>[] = [
        {
            key: 'methodName',
            header: 'Method Name',
            width: 150
        },
        {
            key: 'autoImportPath',
            header: 'Auto Import Path',
            width: 150

        },
        {
            key: 'cmoName',
            header: 'CMO Name',
            width: 150

        },
        {
            key: 'accessKey',
            header: 'Access Key',
            width: 150

        }
    ]

    return (
        <div className="access-key-mgmnt-container">
            <div className="add-cmo-method-pair-container">
                {/* <Test /> */}
                <CmoMethodPairForm
                    accessKeyDetails={accessKeyFormData as AccessKeyDetails}
                    setAccessKeyDetails={handleEdit}
                    cmoList={cmoList}
                    methodList={methodList}
                    hideLoader={hideLoader}
                    showLoader={showLoader} 
                    refreshLists={refreshLists}/>
            </div>
            <div className="added-cmo-method-pair-table-container">
                <AccessKeyTable
                    name="access-key"
                    data={tblData}
                    columns={columnDef}
                    isEditable={true}
                    onEdit={handleEdit}
                    hasAccessKey={true}
                    renewAccessKey={renewAccessKey}
                    hasActivation={true}
                    activated={activateAccessKey} />
            </div>
        </div>

    );

}