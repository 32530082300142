import { useContext, useState } from "react";
import Spinner from "../pages/Spinner"

export type SpinnerType = {
  loader: JSX.Element | null;
  showLoader: () => void,
  hideLoader: () => void
}
export function useSpinner() {
  const [loading, setLoading] = useState(false);
  let spinner: SpinnerType = {
    loader: loading ? <Spinner /> : null,
    showLoader: () => setLoading(true),
    hideLoader: () => setLoading(false),
  }

  return spinner;
}