import { createContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface AuthContextType {
  // UserInformation: UserInformation;
  isAuthenticated: any;
  // setIsAuthenticated: any;
  setCurrentUserType: any;
  currentUserType: () => number;
  setToken: any;
  // token: any;
  // setAccessKey: any;
  // accessKey: any;
  // setMethodName: any;
  // methodName: any;
  getTokenFromSession: any,
  removeTokenFromSession: () => void
}

const AuthContext2 = createContext<AuthContextType>(null!);

const AuthProvider2 = ({ children }: { children: any }) => {
  let navigate = useNavigate();


  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [token, setJwtToken] = useState<string>();
  // const [currentUserType, setCurrentUserType] = useState(null);
  // const [accessKey, setAccessKey] = useState(null);
  // const [methodName, setMethodName] = useState(null);

  function setToken(token: string) {
    if (token != "") {
      localStorage.setItem('session', token);
      // setJwtToken(token);
    }
  }

  function getTokenFromSession() {
    return localStorage.getItem('session');
  }

  function removeTokenFromSession() {
    let redirectLogin = "qubasuserlogin";
    if (Number(localStorage.getItem('utype')) == 1) {
      redirectLogin = "cmologin";
    }
    localStorage.removeItem('session');
    localStorage.removeItem('utype');
    navigate(redirectLogin, { replace: true });
    // setJwtToken("");
    // setIsAuthenticated(false);
  }

  function isAuthenticated() {
    if (localStorage.getItem("session") === null) {
      return false;
    }
    else {
      return true;
    }
  }

  function setCurrentUserType(usertType: number) {
    localStorage.setItem('utype', usertType.toString());
  }

  function currentUserType() {
    if (localStorage.getItem("utype") === null) {
      return 3;
    }
    else {
      return Number(localStorage.getItem("utype"))
    }
  }

  let value = {
    isAuthenticated,
    // setIsAuthenticated,
    setCurrentUserType,
    currentUserType,
    setToken,
    // token,
    // setAccessKey,
    // accessKey,
    // setMethodName,
    // methodName,
    getTokenFromSession,
    removeTokenFromSession
  };

  return (
    <AuthContext2.Provider
      value={value} >
      {children}
    </AuthContext2.Provider>
  );
};

export { AuthContext2, AuthProvider2 };
