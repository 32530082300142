import { ReactNode } from "react";

export type ColumnDefinitionType<T, K extends keyof T> = {
    key: K;
    header: string;
    width?: number;
    isCsvDownload?: boolean;
    isReportDownload?: boolean;
}

export type TableProps<T, K extends keyof T> = {
    name: string
    data: Array<T>;
    columns: Array<ColumnDefinitionType<T, K>>;
    downloadCsv?: (params: T) => void;
    downloadReport?: (params: T) => void;
}

type TableHeaderProps<T, K extends keyof T> = {
    tableName: string;
    columns: Array<ColumnDefinitionType<T, K>>;
}

export const TableHeader = <T, K extends keyof T>({ tableName, columns }: TableHeaderProps<T, K>): JSX.Element => {
    const headers = columns.map((column, index) => {
        const style = {
            width: column.width ?? 100, // 100 is our default value if width is not defined
            // borderBottom: '2px solid black'
            backgroundColor: '#D9D9D9',
            padding: '10px',
        };

        return (
            <th
                key={`${tableName}-headCell-${index}`}
                style={style}
            >
                {column.header}
            </th>
        );
    });

    return (
        <thead>
            <tr>{headers}</tr>
        </thead>
    );
};

type TableRowsProps<T, K extends keyof T> = {
    tableName: string;
    data: Array<T>;
    columns: Array<ColumnDefinitionType<T, K>>;
    onDownloadCsv?: (params: T) => void;
    onDownloadReport?: (params: T) => void;
}

export const TableRows = <T, K extends keyof T>({ tableName, data, columns, onDownloadCsv, onDownloadReport }: TableRowsProps<T, K>): JSX.Element => {
    const style = {
        // border: '1px solid black'
        padding: '10px'
    }
    const rows = data.map((row, index) => {
        return (
            <tr key={`row-${index}`}>
                {columns.map((column, index2) => {
                    if (column.isCsvDownload && onDownloadCsv) {
                        return (
                            <td key={`${tableName}-cell-${index2}`} style={style}>
                                {/* TODO: Check whether converting to ReactNode is fine */}
                                <span className="download-file" onClick={() => { onDownloadCsv(row) }}>{row[column.key] as ReactNode}</span>
                            </td>
                        );
                    }
                    if (column.isReportDownload && onDownloadReport) {
                        return (
                            <td key={`${tableName}-cell-${index2}`} style={style}>
                                {/* TODO: Check whether converting to ReactNode is fine */}
                                <span className="download-file" onClick={() => { onDownloadReport(row) }}>{row[column.key] as ReactNode}</span>
                            </td>
                        );
                    }
                    return (
                        <td key={`${tableName}-cell-${index2}`} style={style}>
                            {/* TODO: Check whether converting to ReactNode is fine */}
                            {row[column.key] as ReactNode}
                        </td>
                    );
                }
                )}
            </tr>
        );
    });

    return (
        <tbody>
            {rows}
        </tbody>
    );
};

interface TableFooter<T, K extends keyof T> {
    range: number[],
    setPage: (page: number) => void,
    page: number,
    slice: Array<T>
}

export const TableFooter = <T, K extends keyof T>({ range, setPage, page, slice }: TableFooter<T, K>): JSX.Element => {

    // useEffect(() => {
    //     if (slice.length < 1 && page !== 1) {
    //         setPage(page - 1);
    //     }
    // }, [slice, page, setPage]);
    return (
        <div className="table-footer">
            {range.map((el, index) => (
                <button
                    key={index}
                    className={`${"pagination-button"} ${page === el ? "active-button" : "inactive-button"
                        }`}
                    onClick={() => { setPage(el) }} >
                    {el}
                </button>
            ))}
        </div>
    );
};