import { useContext, useEffect } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext2 } from "../providers/AuthProvider2";
import { SpinnerContext, SpinnerProvider } from "../providers/SpinnerProvider";
import configData from "./../app-config.json";


const whitelist = [
  '/',
  '/cmologin',
  '/qubasuserlogin'
];

export default function RootLayout() {
  const location = useLocation();
  let bgClass = "";
  for (const path of whitelist) {
    if (path === location.pathname) {
      bgClass = "main-background";
    }
  }
  return (
    <>
      <main className={bgClass}>
        <IdleTimer />
        <RefreshToken />
        <Outlet />
      <div className="version-info">Version 1.0 beta</div>

      </main>
    </>
  );
}

async function refreshToken(authtoken: string, userType: number, removeTokenFromSession: () => void) {
  var newtoken: string = "";
  let headers: { [key: string]: string } = {
    "Content-Type": "application/json; charset=utf-8",
    "Authorization": "Bearer " + authtoken
  };
  const apiUrl = configData.SERVER_URL;
  const url = userType == 1 ? "cmologin/renewtoken" : "qubasuserlogin/renewtoken";

  await fetch(apiUrl + url,
    {
      method: "Post",
      headers,
    }).then(res => {
      if (res.ok) {
        return res.json();
      }
      else {
        if (res.status == 401) {
          toast.error('Unauthorized access');
          removeTokenFromSession();
        } else {
          toast.error('Some error occured please contact your administrator');
        }
      }
    }).then(data => {
      if (data) {
        if (data.isSuccessful) {
          newtoken = data.responseBody;
        }
        else {
          toast.warn(data.responseMessage);
        }
      }
    }).catch(res => {
      toast.error('Some error occured while connecting to the server');
    });
  return newtoken;
}

function RefreshToken() {
  const authContext = useContext(AuthContext2);
  const location = useLocation();

  useEffect(() => {
    // Whitelist certain pages
    let preventReset = false;

    for (const path of whitelist) {
      if (path === location.pathname) {
        preventReset = true;
      }
    }
    if (preventReset) {
      return;
    }

    const handleRefreshToken = async () => {
      var token: string = await refreshToken(authContext.getTokenFromSession(), authContext.currentUserType(), authContext.removeTokenFromSession);
      if (token != "") {
        authContext.setToken(token)
      }
    }

    const interval = setInterval(() => {
      handleRefreshToken();
    }, 540000); // 9 mins of token refresh calls

    if (authContext.isAuthenticated == false) {
      clearInterval(interval);
    }

    // cleanup
    return () => clearInterval(interval);
  }, [authContext.isAuthenticated]);
  return <div />;
}

export const IdleTimer = () => {
  const location = useLocation();
  const authContext = useContext(AuthContext2);
  const navigate = useNavigate();

  let timeout: NodeJS.Timeout | null = null;

  const goBackToHome = () => {
    authContext.removeTokenFromSession();
    authContext.currentUserType() == 1 ? navigate(`/cmologin`) : navigate(`/qubasuserlogin`)
    // code to reset the application
  };

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      goBackToHome();
    }, 600000); // 10 mins of user inactivity logs them out
  };

  const onMouseMove = () => {
    restartAutoReset();
  };

  useEffect(() => {
    // Whitelist certain pages
    let preventReset = false;
    for (const path of whitelist) {
      if (path === location.pathname) {
        preventReset = true;
      }
    }
    if (preventReset) {
      return;
    }

    // initiate timeout
    restartAutoReset();

    // listen for mouse events
    window.addEventListener('mousemove', onMouseMove);

    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [location.pathname]);
  return <div />;
};
