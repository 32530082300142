import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext2 } from "../providers/AuthProvider2";
import QubasUserLogin from "./QubasUserLogin";

export default function QubasUSerProtectedRoutes() {
    const authContext = useContext(AuthContext2);
    if (authContext.isAuthenticated && authContext.currentUserType() == 0) {
        return (<div id="qubas-user"><Outlet /></div>);
    }

    return (<QubasUserLogin />);
    // return (<><Navigate to="/qubasuserlogin" replace /></>);

}