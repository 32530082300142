import './App.css';
import {
  createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider
} from 'react-router-dom';
import RootLayout from './layout/RootLayout';
import QubasUserLogin, { IUserCredentials } from './pages/QubasUserLogin';
import QubasUserDashboard from './pages/qubas-user-dashboard/QubasUserDashboard';
import AuthLayout from './pages/AuthLayout';
import CmoLogin from './pages/CmoLogin';
import QubasUSerProtectedRoutes from './pages/QubasUSerProtectedRoutes';
import CmoProtectedRoutes from './pages/CmoProtectedRoutes';
import CmoDashboard from './pages/cmo-dashboard/CmoDashboard';
import { ToastContainer } from 'react-toastify';


const router = createBrowserRouter(

  createRoutesFromElements(
    <Route element={<AuthLayout />}>
      <Route path="/" element={<RootLayout />}>
        {/* make a home component and use custom logic to take to the home 
          page depending on which route the user. Example, user has logged in
          as client, the home will take them to the home page for the client */}
        {/* <Route index element={<Home />} /> */}
        <Route index element={<Navigate to="cmologin" />} />
        <Route index path="/cmologin" element={<CmoLogin />} />
        <Route path="/qubasuserlogin" element={<QubasUserLogin />} />
        <Route element={<CmoProtectedRoutes />}>
          <Route path="/cmodashboard" element={<CmoDashboard />} />
        </Route>
        <Route element={<QubasUSerProtectedRoutes />}>
          <Route path="/qubasuserdashboard" element={<QubasUserDashboard />} />
        </Route>

        {/* <Route
          path="qubasuserdashboard"
          element={
            <RequireAuth>
              <QubasUserDashboard />
            </RequireAuth>
          }
        /> */}
        {/* <Route path="qubasuserdashboard" element={<QubasUserDashboard />} /> */}
      </Route>
    </Route>
  )
)

function App() {
  return (
    // <div className="App">
    //   <Header />
    //   <Main />
    //   <Footer />
    // </div>
    <>
      <RouterProvider router={router} />
      <ToastContainer position="bottom-right" />

    </>


  );
}


export default App;
