import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext2 } from "../../providers/AuthProvider2";
import { TableHeader, TableRows, TableProps, ColumnDefinitionType, TableFooter } from "./CmoDashboardTable";
import configData from "./../../app-config.json";
import usePagination from "../../hooks/usePagination";

async function handleDownloadFiles(token: string, fileId: number, fileName: string, fileType: string, removeTokenFromSession: () => void) {
    let downloadParams = {
        fileType: fileType,
        fileId: fileId,
    }

    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const body = JSON.stringify(downloadParams);
    const apiUrl = configData.SERVER_URL;
    const url = "filedownloads";
    await fetch(apiUrl + url,
        {
            method: "Post",
            body,
            headers,
        }).then(res => {
            if (res.ok) {
                return res.blob();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then((blob) => {
            //detect whether the browser is IE/Edge or another browser
            // if (window.navigator && window.navigator) {
            //   //To IE or Edge browser, using msSaveorOpenBlob method to download file.
            //   window.navigator.msSaveOrOpenBlob(blob, fileName);
            // }
            if (blob) {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
}

async function handleGetReports(token: string, removeTokenFromSession: () => void) {

    let tData: [] = [];
    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const apiUrl = configData.SERVER_URL;
    const url = "cmoreports";

    await fetch(apiUrl + url,
        {
            method: "Get",
            headers,
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    tData = data.responseBody;
                    // toast.info(data.responseMessage);
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    return tData;
}

interface CMOReportsModel {
    fileId: number,
    fileName: string,
    mD5: string,
    importDate: string,
    reportId: number,
    reportAttachmentId: number,
    statusName: string,
    reportTittle: string,
}

const style2 = {
    // borderCollapse: 'collapse'
} as const

const CMOReportsTable = <T, K extends keyof T>({ name, data, columns, downloadCsv, downloadReport }: TableProps<T, K>): JSX.Element => {
    const [page, setPage] = useState(1);
    const { slice, range } = usePagination(data, page, 10);
    function setP(param: number) {
        setPage(param);
    }
    
    return (
        <div className="reports-table">
            <table style={style2}>
                <TableHeader tableName={name} columns={columns} />
                {
                    data == null &&
                    <tbody>
                        <tr className="no-record-found">
                            <td colSpan={columns.length}>No Record Found</td>
                        </tr>
                    </tbody>

                }
                {
                    data != null && <TableRows
                        tableName={name}
                        data={slice}
                        columns={columns}
                        onDownloadCsv={downloadCsv}
                        onDownloadReport={downloadReport}
                    />
                }

            </table>
            {range.length > 0 && <TableFooter range={range} slice={slice} setPage={setP} page={page} />}
        </div>


    );
};

export default function CmoReports({ hideLoader, showLoader, forceUpdateTrigger }: { hideLoader: () => void, showLoader: () => void, forceUpdateTrigger: number }) {
    const authContext = useContext(AuthContext2);
    const [tblData, setTableData] = useState<CMOReportsModel[]>([]);


    const fetchCmoReports = async () => {
        showLoader();
        const result = await handleGetReports(authContext.getTokenFromSession(), authContext.removeTokenFromSession);
        setTableData(result);
        hideLoader();
    }

    useEffect(() => {
        fetchCmoReports();
    }, [forceUpdateTrigger]);

    async function handleDownloadCsv(fileDetails: CMOReportsModel) {
        await handleDownloadFiles(authContext.getTokenFromSession(), fileDetails.fileId, fileDetails.fileName, "uploadedcsvfromreporttable", authContext.removeTokenFromSession);
    }

    async function handleDownloadReport(fileDetails: CMOReportsModel) {
        await handleDownloadFiles(authContext.getTokenFromSession(), fileDetails.reportAttachmentId, fileDetails.reportTittle, "report", authContext.removeTokenFromSession);
    }

    const columnDef: ColumnDefinitionType<CMOReportsModel, keyof CMOReportsModel>[] = [
        {
            key: 'fileName',
            header: 'File Name',
            width: 150,
            isCsvDownload: true
        },
        {
            key: 'mD5',
            header: 'MD5',
        },
        {
            key: 'importDate',
            header: 'Import Date'
        },
        {
            key: 'reportId',
            header: 'Report Id',
            isReportDownload: true
        },
        {
            key: 'statusName',
            header: 'Status'
        }
    ]

    return (
        <div className="">
            <div className="">
                <CMOReportsTable
                    name="file-upload-history"
                    data={tblData}
                    columns={columnDef}
                    downloadCsv={handleDownloadCsv}
                    downloadReport={handleDownloadReport}
                />
            </div>
        </div>

    );

}
