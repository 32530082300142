import { Outlet } from "react-router-dom";
import { AuthProvider2 } from "../providers/AuthProvider2";
import { SpinnerProvider } from "../providers/SpinnerProvider";

export default function AuthLayout() {
    return (
        <AuthProvider2>
            <SpinnerProvider>
                <Outlet />
            </SpinnerProvider>
        </AuthProvider2>
    );


}