import { isEditable } from "@testing-library/user-event/dist/utils";
import { Key, ReactElement, JSXElementConstructor, ReactFragment, ReactPortal, ReactNode, useContext, useState, useEffect } from "react"
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerType, useSpinner } from "../../hooks/useSpinner";
import { AuthContext2 } from "../../providers/AuthProvider2";
import Tabs from "../../widgets/Tabs";
import AccessKeyManagement from "./AccessKeyManagement";
import CmoManagement from "./CmoManagement";
import { ColumnDefinitionType, TableHeader, TableProps, TableRows } from "./QubasUserDashboardTable";


export default function QubasUserDashboard(): JSX.Element {
    const spinner_cm: SpinnerType = useSpinner();
    const spinner_am: SpinnerType = useSpinner();
    const authContext = useContext(AuthContext2);


    const tabsConfig = [
        {
            label: "CMO Management",
            content: <CmoManagement hideLoader={spinner_cm.hideLoader} showLoader={spinner_cm.showLoader} />,
            icon: ""
        },
        {
            label: "Key Management",
            content: <AccessKeyManagement hideLoader={spinner_am.hideLoader} showLoader={spinner_am.showLoader} />,
            icon: ""
        }
    ];

    const logo = require('../../contents/logo.png');


    return (
        <div className="qubas-user-dashboard">
            {spinner_cm.loader}
            {spinner_am.loader}
            <div className="cmo-dashboard-header-image">
                {/* <NavLink to="/"> Home </NavLink> */}
                <img className="dashboard-logo-image" src={logo} />
                <NavLink className="logout-button" onClick={authContext.removeTokenFromSession} to="/qubasuserlogin" replace> Logout </NavLink>
            </div>
            {/* <Table data={data} columns={columns} /> */}
            <div>
                <Tabs tabsConfig={tabsConfig} />
            </div>
        </div>

    )
}