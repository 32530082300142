import { useState } from "react";

interface TabsConfiguration {
    label: string;
    content: JSX.Element | string;
    icon: string;
}

export default function Tabs({ tabsConfig, defaultIndex }: { tabsConfig: TabsConfiguration[], defaultIndex?: number }): JSX.Element {
    // default index value will be used to set the intial active tab

    const [selectedIndex, setSelectedIndex] = useState(defaultIndex ?? 0);

    const handleClick = (index: number) => setSelectedIndex(index);
    return (
        <>
            <div className="tab-panel-button-container">
                {tabsConfig.map((tab, index) => (
                    <button
                        // className="tab-panel-button"
                        className={`${"tab-panel-button"} ${selectedIndex === index ? "active-button" : "inactive-button"
                        }`}
                        key={`tab-${index}`}
                        onClick={() => handleClick(index)}
                        role="tab"
                        aria-controls={`panel-id-${index}`}
                        aria-selected={selectedIndex === index}
                        id={`tab-id-${index}`}
                    >
                        {tab.icon}
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="tab-panel-content-wrapper">
                {tabsConfig.map((tab, index) => (
                    <section
                        key={`tab-panel-${index}`}
                        hidden={selectedIndex !== index}
                        role="tab-panel"
                        aria-labelledby={`tab-id${index}`}
                        id={`panel-id-${index}`}
                    >
                        {tab.content}
                    </section>
                ))}
            </div>
        </>
    );
};