import { ReactNode, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext2 } from "../../providers/AuthProvider2";
import { AccessKeyDetails, CMOList, MethodList } from "./AccessKeyManagement";
import { ColumnDefinitionType, TableHeader, TableProps, TableRows } from "./QubasUserDashboardTable";
import configData from "./../../app-config.json";

interface CmoMethodPairFormProps {
    accessKeyDetails: AccessKeyDetails,
    setAccessKeyDetails: (akDetails: AccessKeyDetails) => void,
    cmoList: CMOList[],
    methodList: MethodList[],
    hideLoader: () => void,
    showLoader: () => void,
    refreshLists: () => void
}

async function handleAddAccessKey(event: React.FormEvent<HTMLFormElement>, token: string, hideLoader: () => void, showLoader: () => void, refreshListsOnSuccess: () => void, removeTokenFromSession: () => void) {
    event.preventDefault();
    showLoader();
    let formData = new FormData(event.currentTarget);
    let addedCmoMethodPair = {
        cmoId: formData.get("cmoId") as string,
        methodId: formData.get("methodId") as string,
    }

    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const body = JSON.stringify(addedCmoMethodPair);
    const apiUrl = configData.SERVER_URL;
    const url = "accesskeydetails"
    await fetch(apiUrl + url,
        {
            method: "POST",
            headers,
            body,
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                } else {
                    toast.error('Some error occured please contact your administrator');
                }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    // toast.info(data.responseMessage);
                    refreshListsOnSuccess();

                }
                else {
                    toast.warn(data.responseMessage);
                }
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    hideLoader();
}

async function handleUpdateAccessKey(event: React.FormEvent<HTMLFormElement>,
    accessKey: number,
    token: string,
    hideLoader: () => void, showLoader: () => void,
    refreshListsOnSuccess: () => void,
    removeTokenFromSession: () => void,
    resetForm: () => void) {

    event.preventDefault();
    showLoader();
    let formData = new FormData(event.currentTarget);
    let updateCmoMethodPair = {
        accessKeyId: accessKey,
        cmoId: formData.get("cmoId") as string,
        methodId: formData.get("methodId") as string,
    }

    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const body = JSON.stringify(updateCmoMethodPair);
    const apiUrl = configData.SERVER_URL;
    const url = "accesskeydetails"
    await fetch(apiUrl + url,
        {
            method: "PUT",
            headers,
            body,
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                } else {
                    toast.error('Some error occured please contact your administrator');
                }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    // toast.info(data.responseMessage);
                    resetForm();
                    refreshListsOnSuccess();
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    hideLoader();

}

// function AccessKeyForm({ accessKeyDetails, setAccessKeyDetailss }: { accessKeyDetails: AccessKeyDetails, setAccessKeyDetailss: (akDetails: AccessKeyDetails) => void }) {
export default function CmoMethodPairForm({ accessKeyDetails, setAccessKeyDetails, cmoList, methodList, hideLoader, showLoader, refreshLists }: CmoMethodPairFormProps) {
    const authContext = useContext(AuthContext2);
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setAccessKeyDetails({ ...accessKeyDetails, [event.target.name]: event.target.value });
    };

    const resetForm = () => {
        const initialAccessKeyFormstate: AccessKeyDetails = {
            accessKeyId: 0,
            cmoId: 0,
            methodId: 0,
            accessKey: "",
        }
        setAccessKeyDetails(initialAccessKeyFormstate);
    };
    return (
        <form className="add-cmo-method-pair-form" onSubmit={(e) => {
            accessKeyDetails.accessKeyId ?
                handleUpdateAccessKey(e, accessKeyDetails.accessKeyId, authContext.getTokenFromSession(), hideLoader, showLoader, refreshLists, authContext.removeTokenFromSession, resetForm) :
                handleAddAccessKey(e, authContext.getTokenFromSession(), hideLoader, showLoader, refreshLists, authContext.removeTokenFromSession)
        }}>
            <section className="form-contents">
                <header className="form-header">
                    <h1 className="form-title">Add a new CMO and Method pair</h1>
                    <h2 className="form-subtitle">Please note that only those methods are displayed which are not yet taken by a CMO</h2>
                </header>
                <div className="form-fields">
                    <div className="form-group">
                        <label className="form-dropdown-label">
                            CMO Name*{" "}
                        </label>
                        <select className="form-dropdown" name="cmoId" value={accessKeyDetails.cmoId} onChange={onChange} required>
                            <option value={0}>Please select a CMO</option>
                            {cmoList.map((cmo, index) => {
                                return (
                                    <option key={`cmo-${index}`} value={cmo.cmoId}>
                                        {/* TODO: Check whether converting to ReactNode is fine */}
                                        {cmo.cmoName as ReactNode}
                                    </option>
                                );
                            }
                            )}
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="form-dropdown-label">
                            Method Name*{" "}
                        </label>
                        <select className="form-dropdown" name="methodId" value={accessKeyDetails.methodId} onChange={onChange} required>
                            <option value={0}>Please select a Method</option>
                            {methodList.map((method, index) => {
                                return (
                                    <option key={`method-${index}`} value={method.methodId}>
                                        {/* TODO: Check whether converting to ReactNode is fine */}
                                        {method.methodName as ReactNode}
                                    </option>
                                );
                            }
                            )}
                        </select>

                    </div>

                    <div>
                        <button className="form-btn" type="submit">{accessKeyDetails.accessKeyId ? "Update" : "Add"}</button>{" "}
                        <button className="form-btn" type="button" onClick={resetForm}>Reset</button>
                    </div>
                </div>
            </section>
        </form>
    );
}
