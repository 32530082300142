import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext2 } from "../../providers/AuthProvider2";
import { ColumnDefinitionType, TableHeader, TableProps, TableRows } from "./QubasUserDashboardTable";
import configData from "./../../app-config.json";

interface CMODetails {
    id: number;
    companyName: string | undefined;
    personName: string | undefined;
    personEmail: string | undefined;
    active: boolean;
}

async function handleAddCmo(event: React.FormEvent<HTMLFormElement>, token: string, hideLoader: () => void, showLoader: () => void, refreshData: () => void, removeTokenFromSession: () => void) {
    event.preventDefault();
    showLoader();
    let formData = new FormData(event.currentTarget);
    let addedCmo = {
        cmoName: formData.get("companyName") as string,
        pocName: formData.get("personName") as string,
        pocEmail: formData.get("personEmail") as string
    }

    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const body = JSON.stringify(addedCmo);
    const apiUrl = configData.SERVER_URL;
    const url = "cmodetails"
    await fetch(apiUrl + url,
        {
            method: "POST",
            headers,
            body,
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    // toast.info(data.responseMessage);
                    refreshData();
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    hideLoader();
}

async function handleUpdateCmo(event: React.FormEvent<HTMLFormElement>, cmoId: number, isActive:boolean, token: string, hideLoader: () => void, showLoader: () => void, refreshData: () => void, removeTokenFromSession: () => void) {
    event.preventDefault();
    showLoader();
    let formData = new FormData(event.currentTarget);
    let addedCmo = {
        cmoId: cmoId,
        cmoName: formData.get("companyName") as string,
        pocName: formData.get("personName") as string,
        pocEmail: formData.get("personEmail") as string,
        active: isActive
    }

    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const body = JSON.stringify(addedCmo);
    const apiUrl = configData.SERVER_URL;
    const url = "cmodetails"
    await fetch(apiUrl + url,
        {
            method: "PUT",
            headers,
            body,
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    // toast.info(data.responseMessage); 
                    refreshData();
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }
        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    hideLoader();

}

interface AddCmoFormProps {
    cmoDetails: CMODetails,
    setCmoDetails: (cmo: CMODetails) => void,
    hideLoader: () => void
    showLoader: () => void
    refreshData: () => void
}

function AddCmoForm({ cmoDetails, setCmoDetails, hideLoader, showLoader, refreshData }: AddCmoFormProps) {
    const authContext = useContext(AuthContext2);
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCmoDetails({ ...cmoDetails, [event.target.name]: event.target.value });
    };

    const onReset = () => {
        const initialCmoFormstate: CMODetails = {
            id: 0,
            companyName: "",
            personName: "",
            personEmail: "",
            active: true
        }
        setCmoDetails(initialCmoFormstate);
    };
    return (
        <form className="add-cmo-form" onSubmit={(e) => {
            cmoDetails.id ?
                handleUpdateCmo(e, cmoDetails.id, cmoDetails.active, authContext.getTokenFromSession(), hideLoader, showLoader, refreshData, authContext.removeTokenFromSession) :
                handleAddCmo(e, authContext.getTokenFromSession(), hideLoader, showLoader, refreshData, authContext.removeTokenFromSession)
        }}>
            <section className="form-contents">
                <header className="form-header">
                    <h1 className="form-title">Add a new CMO</h1>
                    <h2 className="form-subtitle">Please note that a maximum of 20 CMOs are allowed</h2>
                </header>
                <div className="form-fields">
                    <div className="form-group">
                        <label>
                            <div>CMO Name*{" "}</div>
                            <input
                                name="companyName" value={cmoDetails.companyName} type="text" required={true} onChange={onChange}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            <div>Point of contact (Name)*{" "}</div>
                            <input
                                name="personName" value={cmoDetails.personName} type="text" required={true} onChange={onChange}
                            />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            <div>Point of contact email*{" "}</div>
                            <input className="add-cmo-form-pin-text"
                                name="personEmail" value={cmoDetails.personEmail} type="email" required={true} onChange={onChange}
                            />
                        </label>
                    </div>
                    <div>
                        <button className="form-btn" type="submit">{cmoDetails.id ? "Update CMO" : "Add CMO"}</button>{" "}
                        <button className="form-btn" type="button" onClick={onReset}>Reset</button>
                    </div>
                </div>
            </section>
        </form>
    );
}

const style2 = {
    // borderCollapse: 'collapse'
}

const CmoDetailsTable = <T, K extends keyof T>({ name, data, columns, isEditable, onEdit }: TableProps<T, K>): JSX.Element => {
    return (
        <table style={style2}>
            <TableHeader
                tableName={name}
                columns={columns}
                isEditable={isEditable}
            />
            <TableRows
                tableName={name}
                data={data}
                columns={columns}
                isEditable={isEditable}
                onEditClick={onEdit}
            />
        </table>
    );
};

async function handleGetCmoDetails(token: string, removeTokenFromSession: () => void) {
    let tData: [] = [];
    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token
    };
    const apiUrl = configData.SERVER_URL;
    const url = "cmodetails";

    await fetch(apiUrl + url,
        {
            method: "Get",
            headers,
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                    removeTokenFromSession();
                  } else {
                    toast.error('Some error occured please contact your administrator');
                  }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    tData = data.responseBody;
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }

        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    return tData;
}

export default function CmoManagement({ hideLoader, showLoader }: { hideLoader: () => void, showLoader: () => void }): JSX.Element {
    const authContext = useContext(AuthContext2);
    const [tblData, setTableData] = useState<CMODetails[]>([]);
    const initialCmoFormstate: CMODetails = {
        id: 0,
        companyName: "",
        personName: "",
        personEmail: "",
        active: true,
    }
    const [cmoFormData, setCmoFormData] = useState<CMODetails>(initialCmoFormstate);
    const fetchCMODetails = async () => {
        showLoader();
        const result = await handleGetCmoDetails(authContext.getTokenFromSession(), authContext.removeTokenFromSession);
        setTableData(result);
        hideLoader();
    };
    useEffect(() => {
        fetchCMODetails();
    }, []);

    // const handleClose = (params:CMODetails) => setCmoFormData(params);

    function handleEdit(cmo: CMODetails) {
        setCmoFormData(cmo)
    }

    function refreshData() {
        fetchCMODetails();
    }


    const columnDef: ColumnDefinitionType<CMODetails, keyof CMODetails>[] = [
        {
            key: 'companyName',
            header: 'CMO Name',
            width: 150
        },
        {
            key: 'personName',
            header: 'PoC (Name)',
        },
        {
            key: 'personEmail',
            header: 'PoC (Email)'
        }
    ]

    return (
        <div className="cmo-mgmnt-container">
            <div className="add-cmo-container">
                {/* <Test /> */}
                <AddCmoForm
                    cmoDetails={cmoFormData as CMODetails}
                    setCmoDetails={handleEdit}
                    hideLoader={hideLoader}
                    showLoader={showLoader}
                    refreshData={refreshData} />
            </div>
            <div className="added-cmo-table-container">
                <CmoDetailsTable
                    name="cmo-mngmnt"
                    data={tblData}
                    columns={columnDef}
                    isEditable={true}
                    onEdit={handleEdit} />
            </div>
        </div>

    );

}