import { createContext, useState } from "react";

interface SpinnerContextType {
  // UserInformation: UserInformation;
  isLoading: boolean;
  setIsLoading: any;
}

const SpinnerContext = createContext<SpinnerContextType>(null!);

const SpinnerProvider = ({ children }: { children: any }) => {
  const [isLoading, setIsLoading] = useState(false);

  let value = {
    isLoading,
    setIsLoading
  };

  return (
    <SpinnerContext.Provider
      value={value} >
      {children}
    </SpinnerContext.Provider>
  );
};

export { SpinnerContext, SpinnerProvider };
