import { createContext, MouseEvent, MouseEventHandler, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthContext2 } from "../providers/AuthProvider2";
import LoginHeader from "../layout/LoginHeader";
import { SpinnerType, useSpinner } from "../hooks/useSpinner";
import { toast } from "react-toastify";
import configData from "./../app-config.json";

export interface IUserCredentials {
    userName: string;
    password: string;
    userType: number;
}


// export const LoggedInContext = createContext();

async function handlePinRequired() {
    let isRequired: boolean = false;
    let headers: { [key: string]: string } = {
        "Content-Type": "application/json; charset=utf-8",
    };
    const apiUrl = configData.SERVER_URL;
    const url = "qubasuserlogin";

    await fetch(apiUrl + url,
        {
            method: "Get",
            headers,
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
            else {
                // this should be refactored as authorization is not required for this api endpoint
                // think about the alternative error codes that can be encountered
                if (res.status == 401) {
                    toast.error('Unauthorized access');
                } else {
                    toast.error('Some error occured please contact your administrator');
                }
            }
        }).then(data => {
            if (data) {
                if (data.isSuccessful) {
                    isRequired = data.responseBody;
                }
                else {
                    toast.warn(data.responseMessage);
                }
            }

        }).catch(res => {
            toast.error('Some error occured while connecting to the server');
        });
    return isRequired;
}

export default function QubasUserLogin() {
    const [pin, setPin] = useState("");
    let navigate = useNavigate();
    let location = useLocation();
    const authContext = useContext(AuthContext2);
    const spinner: SpinnerType = useSpinner();
    const [isRequired, setIsRequired] = useState<boolean>(false);

    let from = location.state?.from?.pathname || '/qubasuserdashboard';

    const fetchPinRequired = async () => {
        spinner.showLoader();
        const result = await handlePinRequired();
        setIsRequired(result);
        spinner.hideLoader();
    };

    useEffect(() => {
        fetchPinRequired();
    }, []);

    let enteredPin = "";
    function handlePinClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        enteredPin += e.currentTarget.value;
        setPin(pin + "*");
    }

    let pinButtons = []

    for (var i = 0; i <= 9; i++) {
        pinButtons.push(<button key={i} value={i} className="login-form-pin-btn" onClick={e => handlePinClick(e)}>{i}</button>)
    }

    async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        spinner.showLoader();

        let formData = new FormData(event.currentTarget);
        let userCredentials = {
            userName: formData.get("username")?.toString().trim(),
            password: formData.get("password"),
            userType: 0
        }

        let headers: { [key: string]: string } = { "Content-Type": "application/json; charset=utf-8" };
        const body = JSON.stringify(userCredentials);
        const apiUrl = configData.SERVER_URL;
        const url = "qubasuserlogin"
        await fetch(apiUrl + url,
            {
                method: "POST",
                headers,
                body,
            }).then(res => {
                if (res.ok) {
                    return res.json();
                }
                else {
                    if (res.status == 401) {
                        toast.error('Unauthorized access');
                        authContext.removeTokenFromSession();
                    } else {
                        toast.error('Some error occured please contact your administrator');
                    }
                }
            }).then(data => {
                if (data) {
                    if (data.isSuccessful) {
                        // authContext.setIsAuthenticated(true);
                        authContext.setCurrentUserType(0);
                        authContext.setToken(data.responseBody);
                        navigate("/qubasuserdashboard", { replace: true });
                        // toast.info(data.responseMessage);
                    }
                    else {
                        toast.warn(data.responseMessage);
                    }
                }
            }).catch(res => {
                toast.error('Some error occured while connecting to the server');
            });
        spinner.hideLoader();

    }

    return (
        <div className="login-container">
            {spinner.loader}

            <LoginHeader />

            <div className="login-form-container">
                {/* <Test /> */}
                <form className="login-form" onSubmit={handleLogin}>
                    <section className="login-form-contents">
                        <header className="login-header">
                            <h1 className="login-header-title">Log in</h1>
                            <h2 className="login-header-subtitle">Please enter your account credentials</h2>
                        </header>
                        <div className="login-form-fields">
                            <div className="form-group">
                                <label>
                                    <input
                                        name="username" type="text"
                                    />
                                    <span>Username{" "}</span>
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    <input
                                        name="password" type="password"
                                    />
                                    <span>Password{" "}</span>
                                </label>
                            </div>
                            {isRequired && <div className="login-form-pin-btn-group">
                                {pinButtons}
                            </div> }
                            {isRequired && <div className="form-group">
                                <label>
                                    <input className="login-form-pin-text"
                                        name="pin" type="text" value={pin} readOnly
                                    />
                                    <span>PIN{" "}</span>
                                </label>
                            </div>}
                            <div>
                                <button className="login-submit-btn" type="submit">Log in <span className="icon"></span></button>
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        </div>

    )
}

// function PinButtons(pinButtonClick: (arg0: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void): JSX.Element[] {


//     return array
// }
// function Test() {
//     let auth = useContext(AuthContext2);
//     console.log("auth in test",auth);
//     if (!auth.isAuthenticated) {
//         return (<div>Auth not success</div>);
//     }

//     return (<div>Auth success</div>);
// }


